import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoading]'
})
export class LoadingDirective {
  @Input() appLoading: boolean = false;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }
  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    const parent = this.document.createElement('span');
    parent.setAttribute("id", "customSpinner")
    parent.classList.add("loading-state")

    const child2 = this.document.createElement('div');
    child2.classList.add("loading")

    parent.appendChild(parent)
    if (changes.appLoading.currentValue == false && changes.appLoading.previousValue == true) {
      document.getElementById('customSpinner')?.remove()
      this._renderer.setStyle(this._elRef.nativeElement, 'pointer-events', 'initial');
      this._renderer.setStyle(this._elRef.nativeElement, 'opacity', '1');
    }
    else if (changes.appLoading.currentValue == true) {
      this._renderer.appendChild(this._elRef.nativeElement, parent)
      this._renderer.setStyle(this._elRef.nativeElement, 'pointer-events', 'none');
      this._renderer.setStyle(this._elRef.nativeElement, 'opacity', '0.6');
    }
  }
}
